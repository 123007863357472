export default [
  {
    caption: 'Enable all offers',
    action: 'enableOffers',
  },
  {
    caption: 'Stop all offers',
    action: 'stopOffers',
  },
];
