//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';
import Offers from './Table.vue';

export default {
  components: {
    Offers,
  },

  async mounted() {
    try {
      this.setGeneralLoader(true);
      await this.loadOffers();
      await this.loadCurrencies();
      await this.loadPaymentMethods();
      await this.loadRequisites();
    } catch (error) {
      this.setErrorNotification(error.message);
    } finally {
      this.setGeneralLoader(false);
    }
  },

  methods: {
    ...mapActions('common', ['setGeneralLoader', 'setSuccessNotification', 'setErrorNotification']),
    ...mapActions('tradersOffers', {
      loadOffers: 'loadData',
    }),
    ...mapActions('tradersCurrencies', {
      loadCurrencies: 'loadData',
    }),
    ...mapActions('tradersPaymentMethods', {
      loadPaymentMethods: 'loadData',
    }),
    ...mapActions('tradersRequisites', {
      loadRequisites: 'loadData',
    }),
  },
};
