//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions } from 'vuex';
import constant from '~/const';

export default {
  props: {
    isLoading: { type: Boolean, default: false },
  },

  data() {
    return {
      filters: {
        searchString: '',
        id: null,
        giveCurrency: [],
        getCurrency: [],
        paymentMethodsIds: [],
        dateFrom: '',
        dateTo: '',
        maxLimit: undefined,
        minLimit: undefined,
      },
      constant,
    };
  },

  computed: {
    ...mapState('tradersOffers', {
      filterLists: 'filterLists',
      stateFilters: 'filters',
    }),

    ...mapState('tradersCurrencies', { currencies: 'data' }),
    ...mapState('tradersPaymentMethods', { paymentMethods: 'data' }),
  },

  watch: {
    filters: {
      async handler(data) {
        await this.setFilters(data);
        this.$emit('update');
      },
      deep: true,
    },
    'stateFilters.id': {
      handler(newId) {
        this.filters.id = newId;
      },
    },
  },

  created() {
    this.setFilters({});
  },

  methods: {
    ...mapActions('common', [
      'setGeneralLoader',
      'setGeneralProgress',
      'setSuccessNotification',
      'setErrorNotification',
      'confirmAction',
    ]),

    ...mapActions('tradersOffers', [
      'setFilters',
    ]),
  },
};
